<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";
import CreateEvent from "@/components/events/CreateEvent.vue";
import EditEvent from "@/components/events/EditEvent.vue";

export default {
  name: "Events",
  components: {EditEvent, CreateEvent, TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Events",
        path: "/events",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,

      createEventModal: false,
      editEventModal: false,
      event: {
        id: 0,
        name: "",
        start_date: "",
        end_date: "",
      },
      selectedEvent: {},
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/events-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    dismissCreateEventModal() {
      this.getListData();
      this.createEventModal = false;
    },
    dismissEditEventModal() {
      this.getListData();
      this.editEventModal = false;
    },
    editEvent(event) {
      this.selectedEvent = event;
      this.editEventModal = true;
    },
    handleActions(command) {
      if (command.action === 'edit') {
        this.editEvent(command.data);
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">Events</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/events-list"
              download-file-name="events"
          >
            <el-button
                v-if="checkUsrPermissions(['Event_all', 'Event_create'])"
                type="primary"
                @click="createEventModal = true"
                size="small"
                class="ml-2"
                icon="el-icon-plus"
            >
              Add New
            </el-button>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="name" :ordering="sort" @sortBy="sortBy('name')" title="Event Name/Title"/>
                  <Header active="start_date" :ordering="sort" @sortBy="sortBy('start_date')" title="Start Date"/>
                  <Header active="end_date" :ordering="sort" @sortBy="sortBy('end_date')" title="End Date"/>
                  <Header
                      :sort="false"
                      title="Actions"
                      v-if="
                          checkUsrPermissions([
                            'Event_all', 'Event_create'
                          ])
                        "
                  />
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    {{ data.name }}
                  </td>
                  <td>
                    {{ data.start_date | dateFormat }}
                  </td>
                  <td>
                    {{ data.end_date | dateFormat }}
                  </td>
                  <td
                      v-if="
                          checkUsrPermissions([
                            'Event_all', 'Event_create'
                          ])
                        "
                  >
                    <el-dropdown @command="handleActions">
                      <div class="el-dropdown-link">
                        Actions <i class="el-icon-arrow-down el-icon--right"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-if="checkUsrPermissions(['Event_all', 'Event_create'])"
                            :command="{action: 'edit', data: data}"
                        >
                          Edit
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CModal
        v-if="createEventModal"
        :closeOnBackdrop="false"
        title="Create Event"
        color="primary"
        class="hide-footer custom-close-button"
        size="lg"
        :show.sync="createEventModal"
    >
      <CreateEvent @onSavedEvent="dismissCreateEventModal"></CreateEvent>
    </CModal>

    <CModal
        v-if="editEventModal"
        :closeOnBackdrop="false"
        title="Edit Event Info"
        color="primary"
        class="hide-footer custom-close-button"
        size="lg"
        :show.sync="editEventModal"
    >
      <EditEvent
          :event="selectedEvent"
          @onEventUpdate="dismissEditEventModal"
      ></EditEvent>
    </CModal>
  </div>
</template>