<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">

          <div class="col-md-12">
            <div class="form-group">
              <label>Name*</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="event.name"
                  placeholder="Enter event name or purpose"
                  required
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Start Date*</label>
              <el-date-picker
                  required
                  class="w-100 p-0"
                  v-model="event.start_date"
                  type="date"
                  placeholder="Enter start date"
                  :format="$datePickerFormat"
                  value-format="yyyy-MM-dd"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>End Date*</label>
              <el-date-picker
                  required
                  class="w-100 p-0"
                  v-model="event.end_date"
                  type="date"
                  placeholder="Enter end date"
                  :format="$datePickerFormat"
                  value-format="yyyy-MM-dd"
              />
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "CreateEvent",
  data: () => {
    return {
      event: {
        name: "",
        start_date: "",
        end_date: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store.dispatch("Settings/loading", true);
      this.$store
          .dispatch("Event/store", this.event)
          .then(() => {
            this.$store.dispatch("Settings/loading", false);
            this.$toastr.s(
                "success",
                "Event successfully created.",
                "Event Created"
            );
            setTimeout(() => {
              this.$emit("onSavedEvent");
            }, 350);
          })
          .catch((err) => {
            this.$store.dispatch("Settings/loading", false);
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            for (let field of Object.keys(errors)) {
              this.$toastr.e("error", errors[field][0], "Error!");
            }
          });
    },
  }
};
</script>

<style scoped></style>
